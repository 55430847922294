import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"

import { ContainerRowCol } from "@components/Containers/positioning-wrappers"
import SEO from "@components/SEO/seo"
import { ContactEmail } from "@components/contact-email"
import { StaticNavbarLayout } from "@components/layouts/static-navbar-layout"

import { LINK_PRIVACY_POLICY } from "@helpers/constants/links"
import { SECTION } from "@helpers/constants/style"

// UGC section was inspired by:
// https://blog.nationalparks.nsw.gov.au/usergeneratedcontent/
// https://www.termsfeed.com/blog/4-clauses-host-user-generated-content/#Clause_1_Intellectual_Property

const TermsAndConditionsPage = () => (
  <div>
    <SEO
      title="Terms and Conditions"
      keywords={["Terms of Service", "Terms and conditions"]}
    />

    <StaticNavbarLayout>
      <ContainerRowCol className={SECTION}>
        <Col xs={12}>
          <h1>Terms and Conditions</h1>
          <p>Welcome to www.educats.com.au! (the Site)</p>
          <p>
            These terms and conditions outline the rules and regulations for the
            use of EDUCATS PTY LTD's Website, located at{" "}
            <a href="www.educats.com.au" target="__blank">
              www.educats.com.au
            </a>
          </p>
          <p>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use www.educats.com.au if you do not
            agree to take all of the terms and conditions stated on this page.
          </p>
          <p>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company’s terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client’s needs in respect of
            provision of the Company’s stated services, in accordance with and
            subject to, prevailing law of Netherlands. Any use of the above
            terminology or other words in the singular, plural, capitalization
            and/or he/she or they, are taken as interchangeable and therefore as
            referring to same.
          </p>
          <h2>Cookies and Analytics</h2>
          <p>
            We may employ the use of cookies and/or usage tracking analytics. By
            accessing www.educats.com.au, you agreed to use cookies and usage
            tracking in agreement with the EDUCATS PTY LTD's Privacy Policy.
          </p>
          <p>
            Most interactive websites like EduCats use cookies and usage
            tracking analytics to let us retrieve insightful information that
            helps improve the future direction of EduCats. Cookies and usage
            tracking analytics are used by our website to enable the
            functionality of certain areas to make it easier for people visiting
            our website.
          </p>

          <h2>License</h2>
          <p>
            Unless otherwise stated, EDUCATS PTY LTD and/or its licensors own
            the intellectual property rights for all material on
            www.educats.com.au. All intellectual property rights are reserved.
            You may access this from www.educats.com.au for your own personal
            use subjected to restrictions set in these terms and conditions.
          </p>
          <p>You must not:</p>
          <ul>
            <li>Republish material from www.educats.com.au</li>
            <li>Sell, rent or sub-license material from www.educats.com.au</li>
            <li>
              Reproduce, duplicate or copy material from www.educats.com.au
            </li>
            <li>Redistribute content from www.educats.com.au</li>
          </ul>
          <p>
            Parts of this website offer an opportunity for users to post and
            exchange opinions and information in certain areas of the website.
            EDUCATS PTY LTD does not filter, edit, publish or review Comments
            prior to their presence on the website. Comments do not reflect the
            views and opinions of EDUCATS PTY LTD,its agents and/or affiliates.
            Comments reflect the views and opinions of the person who post their
            views and opinions. To the extent permitted by applicable laws,
            EDUCATS PTY LTD shall not be liable for the Comments or for any
            liability, damages or expenses caused and/or suffered as a result of
            any use of and/or posting of and/or appearance of the Comments on
            this website.
          </p>
          <p>
            EDUCATS PTY LTD reserves the right to monitor all Comments and to
            remove any Comments which can be considered inappropriate, offensive
            or causes breach of these Terms and Conditions.
          </p>
          <p>You warrant and represent that:</p>
          <ul>
            <li>
              You are entitled to post the Comments on our website and have all
              necessary licenses and consents to do so;
            </li>
            <li>
              The Comments do not invade any intellectual property right,
              including without limitation copyright, patent or trademark of any
              third party;
            </li>
            <li>
              The Comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy
            </li>
            <li>
              The Comments will not be used to solicit or promote business or
              custom or present commercial activities or unlawful activity.
            </li>
          </ul>
          <p>
            You hereby grant EDUCATS PTY LTD a non-exclusive license to use,
            reproduce, edit and authorize others to use, reproduce and edit any
            of your Comments in any and all forms, formats or media.
          </p>
          <h2>iFrames</h2>
          <p>
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.
          </p>
          <h2>Content Liability</h2>
          <p>
            We shall not be hold responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.
          </p>
          <h2>Your Privacy</h2>
          <p>
            Please read <Link to={LINK_PRIVACY_POLICY}>Privacy Policy</Link>
          </p>
          <h2>Reservation of Rights</h2>
          <p>
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amend these terms and conditions and its linking policy at any time.
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.
          </p>
          <h2>Removal of links from our website</h2>
          <p>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.
          </p>
          <p>
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </p>
          <h2>User Generated Content</h2>
          <p>
            The Site allows users to create their own content (the Content),
            such as but not limited to:
          </p>
          <ul>
            <li>the submission of responses to questions on the Site</li>
            <li>comments left on the Site</li>
          </ul>
          <p>
            This agreement constitutes a legally binding agreement between you
            and EDUCATS PTY LTD (Parties). By submitting the Content, you will
            be deemed to have read and accepted these terms and conditions and
            agree to be bound by this agreement.
          </p>
          <p>You warrant the following:</p>
          <ol>
            <li>
              The Content that is submitted is your original work and does not
              infringe any rights of any third party, including but not limited
              to, copyright, moral rights, trademarks, trade secrets, privacy,
              publicity, personal or proprietary rights. You own all copyright
              and moral rights in the Content and have full power, title and
              authority to grant the licence referred to at point{" "}
              <strong>5</strong> below and this licence does not infringe the
              rights of any third party.
            </li>
            <li>
              You have complied with all laws and regulations associated with
              acquiring or producing the Content.
            </li>
            <li>
              Photos, text, videos and other mediums included in the Content are
              safe and legal.
            </li>
          </ol>
          <p>The Parties agree:</p>
          <ol start={4}>
            <li>
              Copyright of the Content will remain owned by you. By using the
              Content, EDUCATS PTY LTD and any sub-licensee does not claim
              ownership of the copyright for this work.
            </li>
            <li>
              You hereby grant to EDUCATS PTY LTD a non-exclusive, royalty-free,
              worldwide, revocable (to the extent permitted under point{" "}
              <strong>9</strong> below), non-transferable, perpetual licence
              (including a right of sub-licence) to use, reproduce, modify,
              adapt, delete from, add to, broadcast, communicate and/or publicly
              display, the Content for advertising, marketing, publicity,
              general promotion and exhibition purposes. The Content may be used
              worldwide in any platforms across print and digital including web,
              applications, email and social media channels, pages or accounts.
            </li>
            <li>
              You authorise EDUCATS PTY LTD to disclose the Content to third
              parties to the extent necessary to give effect to the use outlined
              at point <strong>5</strong> above.
            </li>
            <li>EDUCATS PTY LTD will not pay for use of the Content.</li>
            <li>
              EDUCATS PTY LTD can, but do not have to, attribute you as author
              each time the Content is used.
            </li>
            <li>
              You can revoke this licence at any time by contacting us in
              writing at <ContactEmail /> and informing us that you no longer
              want us to use the Content, however such revocation applies to
              future use of Content only. If you revoke the licence, EDUCATS PTY
              LTD will not re-post or implement any additional uses of the
              Content from the time of receiving notice of revocation. EDUCATS
              PTY LTD is under no obligation to delete or destroy content it has
              already published.
            </li>
            <li>
              EDUCATS PTY LTD will not become liable for any copyright
              infringement or other unlawful conduct arising from the Content or
              use of the Content.
            </li>
            <li>
              You indemnify EDUCATS PTY LTD for all losses, costs and expenses
              incurred or suffered by EDUCATS PTY LTD arising from any claim,
              suit, demand, action or proceeding by any person against it where
              such loss or liability was caused by breach of the warranties set
              out above.
            </li>
            <li>
              In the event that the Content contains personal information as
              defined under the Privacy and Personal Information Protection Act
              1998 (NSW), it will be held and used by EDUCATS PTY LTD in
              accordance with EduCat's{" "}
              <Link to={LINK_PRIVACY_POLICY}>Privacy Policy</Link>
            </li>
            <li>
              You are providing your agreement for EDUCATS PTY LTD to use your
              personal information for the purposes outlined at point{" "}
              <strong>5</strong> above, and to disclose your personal
              information to third parties to the extent required to give effect
              to use outlined at point <strong>5</strong> above.
            </li>
          </ol>
          <h2>Disclaimer</h2>
          <p>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:
          </p>
          <ul>
            <li>
              limit or exclude our or your liability for death or personal
              injury;
            </li>
            <li>
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation;
            </li>
            <li>
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
            </li>
            <li>
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
          </ul>
          <p>
            The limitations and prohibitions of liability set in this Section
            and elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty.
          </p>
          <p>
            As long as the website and the information and services on the
            website are provided free of charge, we will not be liable for any
            loss or damage of any nature.
          </p>
        </Col>
      </ContainerRowCol>
    </StaticNavbarLayout>
  </div>
)

export default TermsAndConditionsPage
